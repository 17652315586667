import {
  Outlet,
  useMatch,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import { selectAuth, selectConsents, selectUser } from 'store/store';
import { ApiStatus } from 'types/enums/ApiStatus';

import { ROUTES_DEFINITIONS } from './routes';

function ProtectedRoute() {
  const location = useLocation();

  const { isAuthenticated } = useAppSelector(selectAuth);
  const { user, status } = useAppSelector(selectUser);
  const { privacyPolicyAccepted, piplAccepted, consentsFetched } = useAppSelector(selectConsents);

  const isLogoutPage = useMatch(ROUTES_DEFINITIONS.logout.path);
  const isAcceptTermsPage = useMatch(ROUTES_DEFINITIONS.acceptTerms.path);

  if (!isAuthenticated) {
    return (
      <Navigate
        to={ROUTES_DEFINITIONS.auth.path}
        replace
        state={{ from: (isLogoutPage || isAcceptTermsPage) ? '' : location }}
      />
    );
  }

  if (status == ApiStatus.NOT_CALLED) return null;

  if (location.state?.from) {
    return <Navigate to={location.state?.from} replace />;
  }

  if (!user || !consentsFetched) {
    return null;
  }

  const needsAcceptCompliance = (!privacyPolicyAccepted || (!piplAccepted && user.country === 'China'));

  if ((!user.country || needsAcceptCompliance) && !isAcceptTermsPage && !isLogoutPage) {
    return <Navigate to={ROUTES_DEFINITIONS.acceptTerms.path} replace />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
