import axios, { AxiosInstance } from 'axios';
import { errorInterceptor } from 'services/interceptors';

class ApiClient {
  static refreshToken = '';

  static client: AxiosInstance;

  static init() {
    this.client = axios.create({
      baseURL: window.env.PUBLIC_API_BASE_URL,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.client.interceptors.response.use(
      (response) => response,
      (error) => errorInterceptor(error),
    );
  }

  static setAuthorizationHeader(token: string, refreshToken: string): void {
    this.client.defaults.headers.Authorization = token;
    this.refreshToken = refreshToken;
  }

  static removeAuthorizationHeader() {
    this.client.defaults.headers.Authorization = null;
  }
}

export default ApiClient;
