import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { LpiService } from 'services/lpi/lpi';
import UpdateUserRequest from 'services/lpi/types/User/UpdateUserRequest';
import { initialize, updateContext } from 'store/featureFlags/FeatureFlagsSlice';
import { RootState } from 'store/store';
import { ApiStatus } from 'types/enums/ApiStatus';

const PLATFORM_NAME = window.env.PUBLIC_PLATFORM_NAME;
const PLATFORM_ROLE = window.env.PUBLIC_PLATFORM_ROLE;

type UserNotCalledState = {
  status: ApiStatus.NOT_CALLED;
  errorCode?: never;
  user?: never;
};

type UserLoadedState = {
  status: Exclude<ApiStatus, ApiStatus.NOT_CALLED>,
  user: User,
  errorCode?: number
};

export type UserState = UserNotCalledState | UserLoadedState;

const initialState: UserState = {
  status: ApiStatus.NOT_CALLED,
};

export const fetchUser = createAsyncThunk(
  'user/fetchUser',
  async (userId: number, { rejectWithValue, dispatch, getState }) => {
    try {
      const { featureFlags } = getState() as RootState;
      const user = await LpiService.Users.GetUser(userId);

      if (!featureFlags.initialized) {
        dispatch(initialize({ accountId: `${user.userId}` }));
      } else {
        dispatch(updateContext({
          kind: 'user',
          key: `${user.userId}`,
        }));
      }

      const formattedUser: User = { ...user, userMappings: [] };

      const lpiMapping = user.userMappings?.find((mapping: any) =>
        mapping?.platformName === PLATFORM_NAME
        && mapping?.platformRole === PLATFORM_ROLE,
      );

      if (lpiMapping && lpiMapping.id) {
        const { platformData } = await LpiService.Users.GetUserMapping(userId, lpiMapping.id);
        lpiMapping.platformData = platformData;

        formattedUser.orgOrSchool = platformData ?? '';
        formattedUser.userMappings = [lpiMapping];
      }

      if (window.env.PUBLIC_LAUNCH_DARKLY_CLIENT_KEY) {
        dispatch(updateContext({ key: String(userId) }));
      }

      return formattedUser;
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (request: UpdateUserRequest, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;

      if (state.user.status === ApiStatus.NOT_CALLED) return;

      const { user } = state.user;

      const { orgOrSchool, ...restUserRequest } = request;
      const promises: Promise<any>[] = [];

      promises.push(LpiService.Users.UpdateUser(restUserRequest));

      if (orgOrSchool && orgOrSchool !== user.orgOrSchool) {
        if (user.userMappings && user.userMappings[0]) {
          const mappingId = user.userMappings?.[0].id!;

          promises.push(LpiService.Users.UpdateUserMapping(user.userId!, mappingId, orgOrSchool));
        } else {
          promises.push(LpiService.Users.CreateUserMapping(user.userId!, orgOrSchool));
        }
      }

      return request;
    } catch (error: unknown) {
      return rejectWithValue((error as AxiosError).response?.status);
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState: initialState as UserState,
  reducers: {
    setUser: (
      state,
      { payload }: PayloadAction<UserState['user']>,
    ) => {
      state.user = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // get user
      .addCase(fetchUser.pending, (state) => {
        state.status = ApiStatus.LOADING;
        state.errorCode = undefined;
      })
      .addCase(fetchUser.fulfilled, (
        state,
        { payload },
      ) => {
        state.status = ApiStatus.SUCCEEDED;
        state.user = payload;
      })
      .addCase(fetchUser.rejected, (state, { payload }) => {
        state.status = ApiStatus.FAILED;
        state.errorCode = Number(payload);
      })
      // update user
      .addCase(updateUser.pending, (state) => {
        state.status = ApiStatus.LOADING;
        state.errorCode = undefined;
      })
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        if (!payload) return;

        const { password, ...restPayload } = payload;
        state.status = ApiStatus.SUCCEEDED;
        state.user = restPayload;
      })
      .addCase(updateUser.rejected, (state, { payload }) => {
        state.status = ApiStatus.FAILED;
        state.errorCode = Number(payload);
      });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
