import { AxiosError } from 'axios';
import ApiClient from 'services/axios-config';
import AuthRequest from 'services/lpi/types/Auth/AuthRequest';
import AuthResponse from 'services/lpi/types/Auth/AuthResponse';
import RegisterRequest from 'services/lpi/types/Auth/RegisterRequest';
import ConsentRequest from 'services/lpi/types/Consent/ConsentRequest';
import { Assessment } from 'types/Assessment';

import ResetPasswordRequest from './types/Auth/ResetPasswordRequest';
import { NewAssessmentResponse } from '../../types/CreateAssessmentResponse';

const usersApi = 'users/api/v4/users';
const lpiApi = '/lpi/api/v4';

const PLATFORM_NAME = window.env.PUBLIC_PLATFORM_NAME;
const PLATFORM_ROLE = window.env.PUBLIC_PLATFORM_ROLE;
const PLATFORM_CUSTOMER = window.env.PUBLIC_PLATFORM_CUSTOMER;
const PLATFORM_ID = window.env.PUBLIC_PLATFORM_ID;
const SITE_TYPE = window.env.PUBLIC_SITE_TYPE;

export const LpiService = {
  Auth: {
    Auth: async (request: AuthRequest): Promise<AuthResponse> => {
      try {
        const { data } = await ApiClient.client.post(
          `${usersApi}/login`,
          request,
          {
            headers: {
              'X-Ignored-Interceptors': '401',
            },
          },
        );

        return data;
      } catch (error: unknown) {
        throw error as AxiosError;
      }
    },
    Logout: async (): Promise<void> => {
      try {
        ApiClient.client.post(`${usersApi}/logout`, {}, {
          headers: {
            'X-Ignored-Interceptors': '401',
          },
        });
      } catch (error: unknown) {
        throw error as AxiosError;
      }
    },
    Register: async (request: RegisterRequest): Promise<User> => {
      try {
        const { data } = await ApiClient.client.post(`${usersApi}`, request);
        return data;
      } catch (error: unknown) {
        throw error as AxiosError;
      }
    },
    ForgotPassword: async (email: string): Promise<void> => {
      try {
        const recoverPasswordBody = {
          username: email,
          siteType: SITE_TYPE,
        };

        await ApiClient.client.post(`${usersApi}/recover-password`, recoverPasswordBody);
      } catch (error: unknown) {
        throw error as AxiosError;
      }
    },
    VerifyResetCode: async (code: string): Promise<void> => {
      try {
        await ApiClient.client.head(`${usersApi}/validate-function-code/${code}`);
      } catch (error: unknown) {
        throw error as AxiosError;
      }
    },
    ResetPassword: async ({ code, password }: ResetPasswordRequest): Promise<void> => {
      try {
        const resetPasswordBody = {
          code,
          newPassword: password,
        };
        await ApiClient.client.post(`${usersApi}/change-password`, resetPasswordBody);
      } catch (error: unknown) {
        throw error as AxiosError;
      }
    },
  },

  Users: {
    GetUser: async (userId: number): Promise<User> => {
      try {
        const { data } = await ApiClient.client.get(`${usersApi}/${userId}`);
        return data;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
    UpdateUser: async (user: User): Promise<void> => {
      try {
        const updatedUser = {
          ...user,
          recoveryEmail: user.username,
        };

        await ApiClient.client.put(`${usersApi}/${user.userId}`, updatedUser);
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
    GetUserMapping: async (userId: number, userMappingId: number): Promise<UserMapping> => {
      try {
        const { data } = await ApiClient.client.get(`${usersApi}/${userId}/user-mappings/${userMappingId}`);
        return data;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
    UpdateUserMapping: async (userId: number, userMappingId: number, orgOrSchool: string): Promise<void> => {
      try {
        const mapping = {
          platformData: orgOrSchool,
        };

        await ApiClient.client.put(`${usersApi}/${userId}/user-mappings/${userMappingId}`, mapping);
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
    CreateUserMapping: async (userId: number, orgOrSchool: string): Promise<void> => {
      try {
        const mapping = {
          platformName: PLATFORM_NAME,
          platformCustomer: PLATFORM_CUSTOMER,
          platformRole: PLATFORM_ROLE,
          platformUserId: PLATFORM_ID,
          platformAccountId: '',
          platformData: orgOrSchool,
        };

        await ApiClient.client.post(`${usersApi}/${userId}/user-mappings/`, mapping);
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
  },

  Consents: {
    GetConsents: async (userId: number): Promise<Consent[]> => {
      try {
        const { data } = await ApiClient.client.get(`${usersApi}/${userId}/user-consents`);
        const consents: Consent[] = data.items ?? [];
        return consents;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
    CreateConsent: async (request: ConsentRequest): Promise<void> => {
      try {
        await ApiClient.client.post(`${usersApi}/${request.userId}/user-consents`, request);
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
  },

  Assessments: {
    getAssessmentList: async (): Promise<Assessment[]> => {
      try {
        const { data } = await ApiClient.client.get(`${lpiApi}/lpi-assessments/`);
        return data.items;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
    getQuestions: async (url: string): Promise<GetAssessmentQuestionsResponse> => {
      try {
        const { data } = await ApiClient.client.get(url);
        return data;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
    saveAndContinue: async (assessmentId: string, responses: AssessmentResponse[]): Promise<void> => {
      try {
        await ApiClient.client.put(`${lpiApi}/lpi-assessments/${assessmentId}`, { responses });
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
    create: async (code: string): Promise<NewAssessmentResponse> => {
      try {
        const { data } = await ApiClient.client.post(`${lpiApi}/lpi-assessments`, { accessCode: code });

        return data;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
    getReport: async (id: string): Promise<string> => {
      try {
        const { data: { fileName } } = await ApiClient.client.get(`${lpiApi}/lpi-reports/${id}`);

        return fileName;
      } catch (error: unknown) {
        const axiosError = error as AxiosError;
        throw axiosError;
      }
    },
  },

  Invitation: {
    AcceptInvitation: async (invitationId: string): Promise<void> => {
      try {
        await ApiClient.client.post(
          `/v4/invitations/${invitationId}/respond`,
          {
            response: 'ACCEPT',
          },
          {
            baseURL: window.env.PUBLIC_ENTITLEMENTS_API_BASE_URL,
          },
        );
      } catch (error: unknown) {
        throw error as AxiosError;
      }
    },
  },
};
